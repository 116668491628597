import '@virtualstate/navigation/esnext/polyfill-rollup.js';

export const init = (Alpine) => {
  Alpine.data('modal', component);
};

export const component = function () {
  const initialEntry = navigation.currentEntry,
    initialTitle = document.title;

  return {
    closing: false,
    open: false,
    isAjax: false,
    loading: false,
    content: null,
    interceptPaths: new Set(),
    addAjaxModalPath(regex) {
      navigation.addEventListener('navigate', async (event) => {
        const { destination } = event;
        const destinationState = destination.getState() || {};

        if (!destinationState?.modal) {
          const destinationUrl = new URL(destination.url);

          if (destinationUrl.pathname.match(new RegExp(regex))) {
            event.intercept({
              async handler() {
                await navigation.navigate(destination.url, {
                  history: 'replace',
                  state: {
                    ...destinationState,
                    modal: true
                  }
                }).finished;
              }
            });
          }
        }
      });

      document.addEventListener(
        'mouseenter',
        ({ target }) => {
          if (target.tagName === 'A' && !target.prefetch) {
            const targetUrl = new URL(target.href);

            if (targetUrl.pathname.match(new RegExp(regex))) {
              targetUrl.searchParams.set('modal', true);

              if (
                !document.querySelector(
                  `link[rel="prefetch"][href="${targetUrl}"]`
                )
              ) {
                new Promise((resolve, reject) => {
                  const ms = 500;

                  console.group(`Should prefetch? ${targetUrl}`);
                  console.log(`Waiting ${ms}ms`);

                  target.addEventListener(
                    'mouseleave',
                    (event) =>
                      reject({
                        message: 'Mouse exited target',
                        detail: event
                      }),
                    { once: true }
                  );

                  setTimeout(resolve, ms);
                })
                  .then(() => console.log('Starting prefetch'))
                  .then(() => {
                    const prefetch = document.createElement('link'),
                      loaded = new Promise(
                        (resolve) => (prefetch.onload = resolve)
                      );

                    Array.from(
                      document.querySelectorAll(
                        `a[href="${target.getAttribute('href')}"]`
                      )
                    ).forEach((link) => (link.prefetch = prefetch));

                    prefetch.rel = 'prefetch';
                    prefetch.as = 'document';
                    prefetch.href = targetUrl;

                    document.head.append(prefetch);

                    return loaded;
                  })
                  .then(() => console.log('Finished prefetch'))
                  .catch((e) => console.log(e.message, e))
                  .finally(() => console.groupEnd(targetUrl));
              }
            }
          }
        },
        { capture: true }
      );
    },
    init() {
      const _self = this;

      Alpine.effect(() => {
        if (!this.closing && this.$el.open) {
          this.$el.close();
        }
      });

      Alpine.effect(() => {
        if (this.open && !this.$el.open) {
          this.$el.showModal();
        } else if (!this.open && this.$el.open) {
          this.closing = true;
        }
      });

      this.$el.addEventListener('animationend', () => {
        if (this.closing) this.closing = false;
      });

      this.$el.addEventListener('close', () => {
        this.closing = false;
        this.open = false;
      });

      function openModal(content, { isAjax = false }) {
        _self.open = true;
        _self.loading = true;
        _self.isAjax = isAjax;

        return Promise.resolve(content)
          .then((content) => {
            if (content) {
              _self.content = content;
            }
          })
          .catch((e) => {
            console.error(e);
            _self.content = 'An error occurred. Please try again.';
          })
          .finally(() => {
            _self.loading = false;
          });
      }

      function openAjaxModal({ destination, signal }) {
        return openModal(
          new Promise((resolve) => {
            let state = destination.getState() || {};

            if (state?.content) return resolve(state.content);

            let destinationUrl = new URL(destination.url);

            destinationUrl.searchParams.set('modal', true);

            resolve(
              fetch(destinationUrl, {
                signal,
                cache: 'default',
                mode: 'same-origin',
                priority: 'high',
                headers: {
                  'Content-Type': 'text/html'
                }
              })
                .then((response) => response.text())
                .then((response) =>
                  new DOMParser().parseFromString(response, 'text/html')
                )
                .then((modalDocument) =>
                  navigation.updateCurrentEntry({
                    state: {
                      ...navigation.currentEntry.getState(),
                      backdrop: initialEntry.key,
                      modal: true,
                      content: modalDocument.body.innerHTML,
                      title: modalDocument.title
                    }
                  })
                )
            );
          }),
          { isAjax: true, signal }
        );
      }

      navigation.addEventListener('currententrychange', async (event) => {
        const state = event.target.currentEntry.getState();

        if (state?.modal) {
          let { content, title = initialTitle } = state;

          document.title = title;
          _self.content = content;
          _self.loading = false;
        }
      });

      navigation.addEventListener('navigate', async (event) => {
        const destinationState = event.destination.getState() || {};

        if (
          initialEntry.getState()?.backdrop &&
          initialEntry.getState().backdrop === event.destination?.key
        ) {
          event.intercept({
            async handler() {
              await navigation.navigate(event.destination.url, {
                history: 'replace',
                state: event.destination.getState()
              }).complete;
            }
          });
        } else if (destinationState.modal) {
          event.intercept({
            async handler() {
              await openAjaxModal(event);
            },
            scroll: 'manual'
          });
        } else if (event.destination.key === initialEntry.key) {
          _self.open = false;
        }
      });
    },
    wrapper: {
      'x-ref': 'modal',
      ':class': `{
        modal: true,
        closing: closing,
        loading: loading
      }`,
      '@click.self': 'open = false',
      '@close'() {
        if (this.isAjax && navigation.currentEntry.getState()?.backdrop) {
          navigation.traverseTo(navigation.currentEntry.getState().backdrop);
        }

        this.content = null;
      }
    },
    closeButton: {
      type: 'button',
      '@click': 'open = false',
      ':class': '"close-button"',
      'x-html': '`<span class="sr-only">Close</span>`'
    },
    loader: {
      'x-transition.opacity': '',
      'x-show': 'loading',
      'x-html': '`Loading...`',
      ':class': '"loader"'
    },
    contentWrapper: {
      'x-show': 'content && !loading',
      'x-html': 'content || ``'
    }
  };
};

export default init;
