const { toHTML } = require('../../utils/sanity/index.js');

module.exports = ({ sponsors, text, before, blockHelpers, ...data }) => {
  if (sponsors?.[0]?._id == '6665d776-1805-46d7-9d42-500979fa23fb') {
    before = sponsors.shift();
    if (!before.scaleMultiplier) before.scaleMultiplier = 1.1;
  }

  return `<section class="sponsor-block" ${blockHelpers.id(data)}>
    ${
      before
        ? `<aside>${toHTML({
            _type: 'sponsorLogos',
            sponsors: [before]
          })}</aside>`
        : ``
    }

    <header class="section-header">
      <h1 class="heading-lg">Partners</h1>
    </header>

    ${text ? toHTML(text, data) : ``}

    <ul>
    ${Array.from(
      sponsors
        .reduce((sponsors, { tier, text, ...sponsor }) => {
          if (!sponsors.has(tier))
            sponsors.set(tier, {
              sponsors: [],
              text: []
            });

          let item = sponsors.get(tier);

          item.sponsors.push(sponsor);
          if (text) item.text.push(...text);
          return sponsors;
        }, new Map())
        .entries()
    )
      .map(
        ([title, { text, sponsors }]) => `<li>
            ${title ? `<h2>${title}</h2>` : ``}

            ${toHTML(
              {
                _type: 'sponsorLogos',
                sponsors
              },
              data
            )}

            ${text ? toHTML(text) : ``}
          </li>`
      )
      .join('')}
    </ul>
  </section>`;
};
